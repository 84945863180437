:root {
  --ion-color-primary: #0e9ae6;
  --ion-color-primary-rgb: 14, 154, 230;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0c88ca;
  --ion-color-primary-tint: #26a4e9;

  --ion-color-secondary: #bbd034;
  --ion-color-secondary-rgb: 187, 208, 52;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #a5b72e;
  --ion-color-secondary-tint: #c2d548;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #ff6161;
  --ion-color-danger-rgb: 255, 97, 97;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e05555;
  --ion-color-danger-tint: #ff7171;

  --ion-color-medium: #666c85;
  --ion-color-medium-rgb: 102, 108, 133;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5a5f75;
  --ion-color-medium-tint: #757b91;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

.ios ion-item-divider {
  background: none;
  border-bottom: 1px solid #e4e4e4;
}

.ios body {
  --ion-background-color: #ffffff;
}

.ion-color-nimus-md3-green {
  --ion-color-base: var(--ion-color-light-tint);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-secondary);
  --ion-color-contrast-rgb: var(--ion-color-secondary-rgb);
  --ion-color-shade: var(--ion-color-secondary-shade);
  --ion-color-tint: var(--ion-color-secondary-tint);
}

.ion-color-nimus-md3-green-bg {
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-nimus-md3-blue {
  --ion-color-base: linear-gradient(180deg, #0b98e6 0%, #5fc4e1 100%);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-nimus_red {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-light);
  --ion-color-contrast-rgb: var(--ion-color-light-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}

.ion-color-nimus_blue {
  --ion-color-base: #0b98e6;
}

.ion-color-nimus_auth_btn {
  --ion-color-base: #bbd034;
  --ion-color-primary-contrast: #616161;
}

.ion-color-nimus_green {
  --ion-color-base: #2dd36f;
}

// TODO: Make preference for dark mode, EXAMPLE:
//Toggle theme here (default is dark)
@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #0e9ae6;
    --ion-color-primary-rgb: 14, 154, 230;
    --ion-color-primary-contrast: #212121;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #0c88ca;
    --ion-color-primary-tint: #26a4e9;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #5b8c45; //  #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #212121;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #1b1b1b;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 42, 42, 42;

    --ion-text-color: #f0f0f0;
    --ion-text-color-rgb: 240, 240, 240;

    --ion-color-step-50: #343434;
    --ion-color-step-100: #3e3e3e;
    --ion-color-step-150: #484848;
    --ion-color-step-200: #525252;
    --ion-color-step-250: #5c5c5c;
    --ion-color-step-300: #656565;
    --ion-color-step-350: #6f6f6f;
    --ion-color-step-400: #797979;
    --ion-color-step-450: #838383;
    --ion-color-step-500: #8d8d8d;
    --ion-color-step-550: #979797;
    --ion-color-step-600: #a1a1a1;
    --ion-color-step-650: #ababab;
    --ion-color-step-700: #b5b5b5;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #c8c8c8;
    --ion-color-step-850: #d2d2d2;
    --ion-color-step-900: #dcdcdc;
    --ion-color-step-950: #e6e6e6;

    --ion-item-background: #000000;

    --ion-card-background: #000000;
  }

  .ios ion-modal {
    --ion-background-color: #000000 !important;
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
}
