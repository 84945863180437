ion-card {
  border-radius: 15px;
  margin: 0 10px;
  background-color: var(--ion-background-color);
}

ion-button {
  box-shadow: none;
}

app-header {
  background: var(--ion-color-light-shade);
}

ion-fab {
  right: 30px;
  bottom: 10px;
}

@media screen and (min-width: 1200px) {
  ion-card {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.logout-modal{
  .modal-wrapper{
    height: 200px;
    width: 300px;
    border-radius: 15px;
  }
}
