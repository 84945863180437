.page-card {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 0;
  padding-bottom: 30px;
}

.page-card > ion-card-content {
  min-height: 300px;
  height: 100%;
}

.plants-page-card {
  margin-top: 24px;
  margin-bottom: 24px;
  padding-top: 0;
  padding-bottom: 40px;
}

.plants-page-card > ion-card-content {
  min-height: 300px;
  height: 100%;
}

ion-searchbar {
  .searchbar-input-container {
    .searchbar-input {
      border-radius: 10px;
    }
  }
}

.button-medium {
  min-width: 100px;
}

.button-large {
  min-width: 120px;
}

ion-item {
  --ripple-color: var(--ion-color-nimus_blue);
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-25 {
  border-radius: 25px;
}

.text-bold {
  font-weight: 500;
}

.action-sheet-delete .action-sheet-group .action-sheet-destructive {
  color: var(--ion-color-nimus_red);
}

.item__note {
  font-size: 12px;
  position: relative;
  bottom: 2px;
}

.item__actions {
  width: 100%;
  display: flex;
  min-height: 64px;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.items-list {
  padding-bottom: 12px;
  padding-top: 16px;
}

.items-list:not(:last-child) {
  border-bottom: 2px solid var(--ion-color-medium);
}

.plant-edit-card {
  margin-top: 24px;
  margin-bottom: 94px;
  padding: 0 10px 16px;
}

.plant-card-width {
  min-width: 180px;
  max-width: 600px;
}

.plant-image {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  min-width: 180px;
  max-width: 400px;
  border-radius: 25px;
}

.label-big {
  font-size: large;
}

.label-small {
  font-size: small;
}

ion-toast.success-alert {
  --background: var(--ion-color-light);
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: var(--ion-color-success);
}

ion-toast.error-alert {
  --background: var(--ion-color-light);
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: var(--ion-color-danger);
}

//ion-skeleton-text {
//  --background: var(--ion-color-nimus_blue);
//  --background-rgb: 64, 153, 255;
//}

.button-group-bottom {
  max-width: 500px;
  min-width: 150px;
  margin: 0 auto;
}
